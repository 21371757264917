import React from "react";
import Partners from "../images/partners.png";
import "../App.css"

export default function PartnersBottom() {
  return (
    <div className="w-0 lg:w-full absolute bottom-0 z-[-1]">
      <img src={Partners} alt="Partners" />
    </div>
  );
}