import React from "react";
import Logo from "../images/logo.png";
import "../App.css";

export default function LogoTop() {
  return (
    <div id="logo" className="max-lg:flex max-lg:justify-center absolute top-0 lg:left-0 lg:ml-12 mt-12">
      <img className="2xl:w-1/2 xl:w-1/3 lg:w-1/4 md:w-1/3 sm:w-1/3 w-1/2" src={Logo} alt="Logo" />
    </div>
  );
}
