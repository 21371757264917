import React from "react";
import Particles from "../images/particles.png";
import "../App.css";

export default function ParticlesTop() {
  return (
    <div className="absolute top-0 right-0 lg:w-1/3 w-0">
      <img src={Particles} alt="Particles"/>
    </div>
  );
}
